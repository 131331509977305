.carousel__container {
  position: relative;
  overflow-y: hidden;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.slide__container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.content-container {
  background-color: var(--bs-stack-grey);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-mobile-container{
  background-color: var(--bs-stack-grey);
  height: 100vh;
  display: flex;
  align-items: start;
  padding-top: 5rem;
  justify-content: center;
}

.content-mobile-container--center{
  background-color: var(--bs-stack-grey);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bio-content-container {
  //background-color: var(--bs-narvik-green);
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  @include onlyIPadPro {
    min-height: 60vh;
  }
  .title-fixed-position {
    position: fixed;
    background-color: var(--bs-narvik-green);
    width: 58%;
    z-index: 99;
    // @include onlyIPadPro{
    //   height: 6rem;
    //   display: flex;
    //   align-items: center;
    // }
    @include mobileScreen {
      width: 100%;
      margin: auto;
      left: 0;
      right: 0;
      height: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
}

.foundation-content-container {
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  .title-fixed-position {
    position: fixed;
    background-color: var(--bs-celeste-green);
    width: 58%;
    z-index: 99;
    @include mobileScreen {
      width: 100%;
      margin: auto;
      left: 0;
      right: 0;
      height: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
}

.img-container {
  max-height: 15rem;
  object-fit: contain;
}

.layer-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  .layer-img-container {
    max-height: 14.5rem;
    object-fit: contain;
  }
}

.title-container {
  padding-top: $fs-32px;
}

.content-description {
  height: 8rem;
  @include mobileScreen {
    min-height: 8rem;
  }
}

.date--position {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-bottom: $fs-3px;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--bs-stack-grey);
  video {
    object-fit: cover;
    &:focus {
      outline: none !important;
    }
  }
}

.header--container {
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 100%;
  z-index: 999;
  left: 0;
  right: 0;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include setPadding($fs-12px, $fs-32px, $fs-12px, $fs-32px);
  @include mobileScreen {
    @include setPadding($fs-12px, $fs-16px, $fs-12px, $fs-16px);
  }
  @include onlyIPadPro {
    height: 6rem;
  }
  .common-width {
    width: calc(100% / 2);
  }
  .img-item {
    width: 35%;
    @include desktop-max-portrait {
      width: 45%;
    }
    @include onlyIPadPro {
      width: 60%;
    }
    @include mobileScreen {
      width: 75%;
    }
  }
  .img-menu-icon {
    width: 6.5%;
    @include desktop-max-portrait {
      width: 12%;
    }
    @include onlyIPadPro {
      width: 20%;
    }
    @include mobileScreen {
      width: 25%;
      @include desktop {
        width: 20%;
      }
    }
  }
}

.content-title {
  height: 2.5rem;
  @include desktop-max-portrait {
    height: 4.5rem;
  }
}

.social-media--container {
  position: absolute;
  z-index: 9;
  display: flex;
  align-items: center;
  left: 0;
  right: 0;
  bottom: $fs-28px;
  width: 10%;
  @include setPadding($fs-12px, $fs-32px, $fs-12px, $fs-32px);
  .social-icon {
    width: 100%;
  }
  @include desktop-max-portrait {
    width: 20%;
  }
}

.menu-list-container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @include desktop-max-portrait {
    width: 85%;
  }
  @include onlyIPadPro {
    width: 80%;
  }
  @include mobileScreen {
    width: 75%;
  }
}

.fly-brid--container {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 90%;
  .fly-brids {
    width: 20%;
    &:hover {
      cursor: pointer;
      transform: scale3d(1.15, 1.15, 1.15);
    }
  }
}

.singature--container {
  // position: absolute;
  // width: 100%;
  // z-index: 9;
  // left: 0;
  // right: 0;
  // bottom: $fs-16px;
  position: absolute;
  width: 50%;
  z-index: 9;
  left: 0;
  right: 0;
  bottom: 0.5em;
  margin: auto;
  @include desktop-max-portrait {
    width: 60%;
  }
  @include mobileScreen {
    width: 85%;
  }
  .singature-img {
    width: 40%;
    @include desktop-max-portrait {
      width: 50%;
    }
  }
}

.banner-img-container {
  position: absolute;
  top: 16%;
  height: 84vh;
  left: 12rem;
  right: 0;
  bottom: 0;
  z-index: 9;
  img {
    object-fit: contain;
  }
  @include desktop-max-portrait {
    top: 25%;
    height: 75vh;
  }
  @include mobileScreen {
    left: 6rem;
    top: 20%;
    height: 80vh;
  }
}

.banner-title--container {
  position: absolute;
  bottom: 4rem;
  z-index: 99;
  width: 44%;
  left: 0;
  right: 0;
  margin: auto;
  // @include desktop-max {
  //   bottom: 15rem;
  //   width: 68%;
  // }
  @include desktop-max-portrait {
    width: 70%;
    bottom: 8.5rem;
  }
  @include onlyIPadPro {
    width: 65%;
  }
  @include mobileScreen {
    width: 85%;
    @include xsmall {
      width: 95%;
    }
    @include phone {
      width: 95%;
    }
  }
}

.year-container {
  margin-bottom: 8.5rem;
  @include mobileScreen{
    margin-bottom: 5.5rem;
  }
}

.scroll-content-container {
  min-height: calc(100vh - 33vh);
  max-height: calc(100vh - 33vh);
  overflow-y: hidden;
  scroll-behavior: smooth;
  padding-right: $fs-32px;
  &.medium-content {
    min-height: calc(100vh - 55vh);
    max-height: calc(100vh - 55vh);
  }
  &.medium-large-content {
    min-height: calc(100vh - 45vh);
    max-height: calc(100vh - 45vh);
  }
  &.large-content {
    min-height: calc(100vh - 40vh);
    max-height: calc(100vh - 40vh);
  }
}

.arrow--container {
  position: absolute;
  left: 0;
  right: 0rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  bottom: 1rem;
  cursor: pointer !important;
}

.arrow-container {
  width: 2rem;
  height: 2rem;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.35rem;
  cursor: pointer !important;
}

.more-link-img__container {
  height: 6.5rem;
  img {
    object-fit: contain;
  }
}

.bio-footer-container {
  // position: absolute;
  // width: 100%;
  // bottom: 0;
  background: var(--bs-white);
}

.player-grid-wrapper {
  position: relative;
  border-radius: $fs-16px;
  overflow: hidden;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.anniversary {
  .anniversary-spec--container {
    position: absolute;
    top: 3.35rem;
    right: 6rem;
    @include mobileScreen {
      right: 1rem;
      top: 1.5rem;
    }
    .info {
      font-size: $fs-64px !important;
      @include mobileScreen {
        font-size: $fs-26px !important;
      }
    }
  }
  .anniversary-year--text {
    position: absolute;
    top: 5.5rem;
    right: 2rem;
    z-index: 999;
    @include mobileScreen {
      right: -1rem;
      top: 3rem;
    }
    .info {
      font-size: $fs-64px !important;
      @include mobileScreen {
        font-size: $fs-26px !important;
      }
    }
  }
  .anniversary-img--container {
    position: absolute;
    width: 100%;
    z-index: 9;
    left: 1rem;
    right: 0;
    bottom: -9.5rem;
    text-align: center;
    @include mobileScreen{
      width: 90%;
      margin: auto;
      left: 0;
      bottom: -7.5rem;
    }
    img {
      width: 50%;
      @include mobileScreen {
        width: 85%;
      }
    }
    .anniversary-title--container {
      position: absolute;
      bottom: 4.5rem;
      z-index: 99;
      width: 44%;
    }
  }
}

.banner-img--container {
  width: 35%;
  margin: auto;
  margin-bottom: 4rem;
  @include iPad{
    width: 45%;
  }
  @include desktop-max-portrait {
    width: 65%;
  }
  @include onlyIPadPro {
    width: 65%;
  }
  @include mobileScreen {
    width: 95%;
    @include xsmall{
      width: 80%;
    }
    @include tablet{
      width: 65%;
    }
    @include desktop {
      width: 75%;
    }
  }
}

.banner-info--container {
  position: absolute;
  bottom: 0rem;
  z-index: 99;
  left: 0;
  right: 0;
  width: 70%;
  margin: auto;
  @include desktop-max-portrait {
    width: 100%;
  }
  @include onlyIPadPro {
    width: 95%;
  }
  @include mobileScreen {
    width: 100%;
    bottom: -4rem;
  }
  .title-container {
    width: 65%;
    margin: auto;
    @include iPad{
      width: 85%;
    }
    @include onlyIPadPro {
      width: 80%;
    }
    @include desktop-max-portrait {
      width: 80%;
    }
    @include mobileScreen {
      width: 85%;
    }
  }
  .banner-description--container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .bottom--img-container {
    width: 95%;
    margin: auto;
    display: flex;
    @include onlyIPadPro {
      height: 6rem;
    }
    @include desktop-max-portrait {
      width: 100%;
    }
  }
}

.play-icon--container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 9;
  img {
    width: 8.5rem;
    height: 8.5rem;
  }
}

.donate-content-container {
  background-color: var(--bs-danger-light);
  height: 100vh;
  // width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .donate--container {
    width: 50%;
    margin-top: 2rem;
    @include iPad{
      width: 65%;
    }
    @include desktop-max-portrait {
      width: 80%;
    }
    @include onlyIPadPro {
      width: 80%;
    }
    @include mobileScreen {
      width: 95%;
    }
    .donate-center-img--container {
      position: absolute;
      top: -52%;
      width: 40%;
      left: 1.5rem;
      right: 0;
      margin: auto;
      @include mobileScreen {
        top: -80%;
        width: 55%;
      }
    }
    .donate-info-img--container {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      margin: auto;
      bottom: 1rem;
      z-index: 99;
      @include mobileScreen {
        bottom: -3rem;
      }
    }
    .donate-btn--container {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -2rem;
    }
    .btn--small {
      padding-top: 0.25rem;
      padding-left: 0.25rem;
      padding-right: 0.25rem;
    }
  }
}

.initiatives-container {
  width: 45%;
  margin: auto;
  // height: 100vh;
  padding-top: 3rem;
  @include desktop-max-portrait {
    width: 90%;
    padding-top: 4rem;
  }
  @include onlyIPadPro {
    width: 80%;
  }
  @include mobileScreen {
    width: 95%;
    padding-top: 0rem;
  }
  .initiative-right-img--container {
    position: absolute;
    top: 28%;
    left: 0;
    right: 8rem;
    width: 35%;
    margin-left: auto;
    @include mobileScreen {
      right: 4rem;
    }
    @include desktop-max-portrait {
      width: 40%;
    }
  }
  .initiative-left-img--container {
    position: absolute;
    top: 18%;
    left: -2.5rem;
    right: 0;
    width: 38%;
    margin-right: auto;
    @include mobileScreen {
      left: -1rem;
    }
  }
  .initiative-title--container {
    position: absolute;
    top: 60%;
    left: -1.5rem;
    right: 0;
    z-index: 99;
    @include onlyIPadPro {
      top: 75%;
    }
    @include mobileScreen {
      left: 0;
      right: 0;
      margin: auto;
      top: 75%;
    }
    .subTitle {
      font-size: 3.5rem !important;
      line-height: calc(3rem * 1.14) !important;
      @include mobileScreen {
        font-size: 2.5rem !important;
        line-height: calc(2.5rem * 1.14) !important;
      }
    }
    .title {
      font-size: 9rem !important;
      line-height: calc(8rem * 1.14) !important;
      @include mobileScreen {
        font-size: 5rem !important;
        line-height: calc(4.5rem * 1.14) !important;
      }
    }
  }
}

.info-container {
  position: absolute;
  width: 100%;
  z-index: 999;
  left: 0;
  right: 0;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  bottom: 0;
  .scroll-icon {
    position: relative;
    width: 1.25rem;
    height: 2rem;
    bottom: 0.4rem;
    border: 2px solid var(--bs-black);
    border-radius: 20px;
    padding: 0.25rem;
    .dot {
      position: absolute;
      width: 0.25rem;
      height: 0.35rem;
      background: #000;
      border-radius: 50%;
      left: 0;
      right: 0;
      top: 25%;
      z-index: 999;
      margin: auto;
    }
  }
}

.blog-container {
  background-color: var(--bs-white-smoke);
  min-height: 100vh;
  .blog-player {
    position: relative;
    //height: 80vh;
    object-fit: contain;
    width: 100%;
    //overflow: hidden;
    top: 0rem;
    video {
      object-fit: cover;
    }
  }
}

.blog-header--container {
  width: 100%;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7rem;
  position: fixed;
  top: 0;
  background-color: var(--bs-white-smoke);
  @include setPadding($fs-32px, 0, $fs-32px, 0);
  @include mobileScreen {
    background-color: $white;
    height: 4rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .common-width {
    width: calc(100% / 2);
  }
  .img-item {
    width: 100%;
    @include mobileScreen {
      width: 50%;
    }
  }
  .img-menu-icon {
    width: 65%;
    margin-bottom: 1rem;
    @include mobileScreen {
      width: 100%;
      margin-bottom: 0;
    }
  }
  .menu-list-container {
    position: absolute;
    top: 1rem;
    right: 0;
    left: 0;
    z-index: 999;
    width: 94%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include onlyIPadPro {
      width: 92%;
    }
    @include mobileScreen {
      width: 88%;
    }
  }
  .blog-menu-list--container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55%;
    top: 30%;
    left: 8rem;
    right: 0;
    margin: auto;
    z-index: 999;
    background-color: var(--bs-white-smoke);
    .line {
      &::before {
        position: absolute;
        top: 4px;
        content: "|";
        color: var(--bs-black);
      }
    }
    @include desktop-max-portrait {
      width: 70%;
      top: 18%;
    }
  }
}

.menu-list-bg-container {
  background-color: $white;
  padding: 0 1rem;
}

.line-container {
  border: 0.1px solid $black;
  width: 100%;
  position: relative;
  .date {
    position: absolute;
    top: -13px;
    right: -3px;
    background-color: var(--bs-white-smoke);
    padding: 0 0.45rem;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.trustees-content-container {
  background-color: var(--bs-celeste);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trustees-content-container {
  background-color: var(--bs-celeste);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.temple-img--container {
  position: absolute;
  top: 3rem;
  width: 35%;
  margin: auto;
  left: 5rem;
  right: 0;
  @include mobileScreen {
    top: 2rem;
    width: 40%;
  }
}

.parampara-content-container {
  background-color: var(--bs-celeste);
  min-height: 100vh;
  .parampara-title-container {
    width: 45%;
    margin: auto;
    position: absolute;
    bottom: -1.85rem;
    left: 0;
    right: 0;
    @include iPad {
      width: 60%;
      @include onlyIPadPro {
        width: 70%;
      }
    }
    @include mobileScreen {
      width: 80%;
      bottom: -1rem;
    }
    .title--position {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 0.5rem;
    }
  }
}

.parampara-img--container {
  height: auto;
  @include iPad {
    height: auto;
  }
  @include mobileScreen {
    height: 100vh;
    object-fit: cover;
  }
}

.parampara-guru-img-container {
  width: calc(100% / 5);
  padding: $fs-16px;
  img {
    object-fit: contain;
  }
  @include onlyIPadPro {
    width: calc(100% / 3);
  }
  @include mobileScreen {
    width: calc(100% / 2);
    @include desktop {
      width: calc(100% / 3);
      padding: $fs-8px;
    }
  }
}

.guru-mobile-title--container {
  position: absolute;
  bottom: $fs-32px;
  left: 0;
  right: 0;
}

.scroll-content-container-scroll {
  min-height: calc(100vh - 33vh);
  max-height: calc(100vh - 33vh);
  overflow-y: scroll;
  scroll-behavior: smooth;
  padding-right: $fs-32px;
  &.medium-content {
    min-height: calc(100vh - 55vh);
    max-height: calc(100vh - 55vh);
  }
  &.medium-large-content {
    min-height: calc(100vh - 45vh);
    max-height: calc(100vh - 45vh);
  }
  &::-webkit-scrollbar {
    width: $fs-4px !important;
    height: $fs-4px !important;
  }
}

.acknowledgments-container {
  background-color: var(--bs-narvik-green);
  min-height: 100vh;
  display: flex;
  // align-items: center;
  justify-content: center;
  overflow-x: hidden;
  .acknowledgments-line {
    &::before {
      content: "";
      position: absolute;
      border-left: 1px solid var(--bs-melanzane-Violet);
      height: $fs-42px;
    }
  }
}

.volunteer-container {
  background-color: var(--bs-narvik-green);
  min-height: 100vh;
  display: flex;
  // align-items: center;
  justify-content: center;
}

.chairman-content-container {
  background-color: var(--bs-narvik-green);
  min-height: 100vh;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  .title-fixed-position {
    position: fixed;
    background-color: var(--bs-narvik-green);
    width: 50%;
    z-index: 99;
    @include mobileScreen {
      width: 100%;
      margin: auto;
      left: 0;
      right: 0;
      height: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
}

.thank-you-content-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: var(--bs-celeste);
}

.influence-container {
  .img-position {
    object-fit: contain;
    height: 100vh;
    @include onlyIPadPro {
      height: 65vh;
    }
    @include desktop-max-portrait {
      height: auto;
    }
    @include mobileScreen {
      height: 50vh;
      @include desktop {
        height: 70vh;
      }
    }
  }
  .title-container {
    position: absolute;
    top: 45%;
    width: 30%;
    left: 15%;
    @include onlyIPadPro {
      width: 40%;
    }
    @include mobileScreen {
      top: 40%;
      width: 70%;
      left: 8%;
    }
  }
}

.donate-info-title-container {
  position: absolute;
  top: 0.05rem;
  width: 90%;
  margin: auto;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.mobile-header-container {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  background-color: var(--bs-stack-grey);
  min-height: 100vh;
  top: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  .menu-close-icon{
    position: absolute;
    top: 1rem;
    right: 2rem;
    width: 3rem;
  }
}
