.common-select-drop-down__control {
  background-color: $white !important;
  border: solid $fs-1px var(--bs-white-smoke) !important;
  font-size: $fs-12px;
  cursor: pointer !important;
  @include setFont(800, normal, normal, normal, left);
  min-height: $fs-32px !important;
  height: auto;
  font-family: $gothamBook;
  color: $black !important;
  border-color: var(--bs-white-smoke) !important;
  border-radius: $fs-4px;
  @include mobileScreen {
    min-height: $fs-32px !important;
    font-size: $fs-12px;
  }
}
.common-select-drop-down__option {
  @include setFont(300, normal, normal, normal, left);
  color: $black !important;
  font-size: $fs-12px !important;
  font-family: $gothamBook;
  cursor: pointer !important;
  text-transform: uppercase !important;
  @include setPadding(
    $fs-4px !important,
    $fs-4px !important,
    $fs-4px !important,
    $fs-4px !important
  );
  &:hover {
    color: $white !important;
    background-color: $themeColor !important;
  }
}

.common-select-drop-down__control__placeholder {
  font-family: $gothamBook;
  opacity: 0.45 !important;
  font-size: $fs-13px !important;
}

.common-select-drop-down__control__control {
  border-color: var(--bs-white-smoke) !important;
}

.common-select-drop-down__dropdown-indicator {
  padding: $fs-4px !important;
}
.common-select-drop-down__indicator {
  padding: $fs-4px !important;
}

.common-select-drop-down__option--is-selected {
  color: $white !important;
  background-color: $themeColor !important;
}

.common-input-select-drop-down__control__control {
  min-height: $fs-38px !important;
}

.common-input-select-drop-down__control {
  background-color: $white !important;
  border: solid $fs-1px var(--bs-white-smoke) !important;
  font-size: $fs-12px;
  cursor: pointer !important;
  @include setFont(300, normal, normal, normal, left);
  min-height: $fs-42px !important;
  height: auto;
  font-family: $gothamBook;
  color: $black !important;
  border-radius: $fs-4px;
  border-color: var(--bs-white-smoke) !important;
  @include mobileScreen {
    min-height: $fs-38px !important;
    font-size: $fs-12px;
  }
}

.common-input-select-drop-down__control__placeholder {
  font-family: $gothamBook;
  opacity: 0.45 !important;
  font-size: $fs-13px !important;
}

.common-input-select-drop-down__control__single-value {
  font-family: $gothamBook;
  color: $black !important;
  text-transform: uppercase !important;
}

.common-input-select-drop-down__control__option {
  @include setFont(300, normal, normal, normal, left);
  color: $black !important;
  font-size: $fs-12px !important;
  font-family: $gothamBook;
  cursor: pointer !important;
  @include setPadding(
    $fs-4px !important,
    $fs-4px !important,
    $fs-4px !important,
    $fs-4px !important
  );
  &:hover {
    color: $white !important;
    background-color: $themeColor !important;
  }
}

.common-input-select-drop-down__control__menu {
  z-index: 9 !important;
}

.common-select-drop-down-large__control {
  background-color: $white !important;
  border: solid $fs-1px var(--bs-white-smoke) !important;
  font-size: $fs-15px;
  cursor: pointer !important;
  @include setFont(300, normal, normal, normal, left);
  min-height: $fs-36px !important;
  height: auto;
  font-family: $gothamBook;
  color: $black !important;
  border-color: var(--bs-white-smoke) !important;
  border-radius: $fs-4px;
  @include mobileScreen {
    min-height: $fs-36px !important;
    font-size: $fs-12px;
  }
}

.common-select-drop-down-large__option {
  @include setFont(300, normal, normal, normal, left);
  color: $black !important;
  font-size: $fs-14px !important;
  font-family: $gothamBook;
  cursor: pointer !important;
  @include setPadding(
    $fs-4px !important,
    $fs-4px !important,
    $fs-4px !important,
    $fs-4px !important
  );
  &:hover {
    color: $white !important;
    background-color: $themeColor !important;
  }
}

.common-select-drop-down-large__control__placeholder {
  font-family: $gothamBook;
  opacity: 0.45 !important;
  font-size: $fs-13px !important;
}

.common-select-drop-down-large__control__control {
  border-color: $stack-grey !important;
}

.common-select-drop-down-large__dropdown-indicator {
  padding: $fs-4px !important;
}
.common-select-drop-down-large__indicator {
  padding: $fs-4px !important;
}
.common-select-drop-down-large__option--is-selected {
  color: $white !important;
  background-color: $themeColor !important;
}

.common-select-drop-down-large__single-value--is-disabled {
  font-family: $gothamBook;
  color: $black !important;
}
.common-select-drop-down-large__placeholder {
  opacity: 0.45 !important;
  font-size: $fs-13px !important;
}

/// Meterial UI Compoennt

.menu-open__container {
  @include setPadding(0 !important, 0 !important, 0 !important, 0 !important);
  .MuiButton-label {
    font-family: $gothamBook;
    @include setFont(500, normal, normal, normal, center);
    @include setMargin(0, 0, 0, 0);
    @include setPadding($fs-2px, $fs-6px, $fs-2px, $fs-6px);
    font-size: $fs-15px !important;
    color: $white;
    text-transform: capitalize;
    &:focus {
      box-shadow: none !important;
      outline: none !important;
    }
    &:active {
      box-shadow: none !important;
    }
  }
  &:focus {
    box-shadow: none !important;
    outline: none !important;
  }
  &:active {
    box-shadow: none !important;
  }
  &.menu-open--small {
    border: 1px solid var(--bs-white-smoke);
    padding: $fs-4px $fs-4px !important;
    width: 8rem;
    .MuiButton-label {
      font-size: $fs-12px;
      color: var( --bs-light-blue) ;
      display: flex;
      justify-content: space-between;
      text-transform: capitalize;
    }
  }
  &.menu-open--large {
    border: 1px solid var(--bs-white-smoke);
    padding: $fs-6px $fs-8px !important;
    width: 8rem;
    .MuiButton-label {
      font-size: $fs-12px;
      color: var( --bs-light-blue) ;
      display: flex;
      justify-content: space-between;
      text-transform: capitalize;
    }
  }
}

.menu-open-item__contianer {
  top: $fs-16px !important;
  .MuiMenu-paper {
    max-height: calc(100% - 20px) !important;
    width: 14rem;
    border-radius: $fs-6px;
  }
  .menu-open-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: $fs-2px solid var( --bs-light-blue)  !important;
    @include setPadding(
      $fs-8px !important,
      $fs-6px !important,
      $fs-8px !important,
      $fs-6px !important
    );
    min-height: auto !important;
    &:last-child {
      border-bottom: none !important;
    }
  }
  &.menu-open-item--small {
    top: $fs-45px !important;
    .MuiMenu-paper {
      width: 10rem;
    }
  }
  &.menu-open-item--large {
    top: $fs-45px !important;
    position: relative;
    .MuiMenu-paper {
      top: 150px !important;
      max-height: calc(60% - 100px) !important;
      // min-height: calc(100% - 100px) !important;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
  @include mobileScreen {
    &.menu-open--mobile-postion {
      .MuiMenu-paper {
        left: 0 !important;
        right: 0 !important;
        margin: auto;
        width: 70% !important;
        top: 20% !important;
      }
      .menu-open-item {
        @include setPadding(
          $fs-14px !important,
          $fs-6px !important,
          $fs-14px !important,
          $fs-6px !important
        );
      }
    }
  }
}

.common-select-drop-down__single-value{
    text-transform: uppercase !important;
}