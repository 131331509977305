.custom-theme-btn {
  box-shadow: 0px $fs-8px $fs-6px #72c3db29;
  font-family: $gothamBook;
  font-size: $fs-14px !important;
  border-radius: $fs-4px !important;
  @include setFont(800 !important, normal, normal, normal, center);
  @include setPadding(
    $fs-8px !important,
    $fs-8px !important,
    $fs-8px !important,
    $fs-8px !important
  );
  @include setBtnColor(
    $light-red !important,
    $light-red !important,
    $white !important
  );
  &:hover {
    @include setBtnColor(
      $white !important,
      //background-color
      $white !important,
      //border-color
      $light-red !important//color
    );
    border-color: $light-red !important;
  }
  &:focus {
    box-shadow: none !important;
    @include setBtnColor(
      $white !important,
      $white !important,
      $light-red !important
    );
    border-color: $light-red !important;
  }
  &:disabled {
    @include setBtnColor(
      $light-red !important,
      $light-red !important,
      $white !important
    );
    cursor: not-allowed;
    border-color: $light-red !important;
  }
}

.custom-green-btn {
  box-shadow: 0px $fs-8px $fs-6px #72c3db29;
  font-family: $gothamBook;
  font-size: $fs-14px !important;
  border-radius: $fs-4px !important;
  @include setFont(800 !important, normal, normal, normal, center);
  @include setPadding(
    $fs-8px !important,
    $fs-8px !important,
    $fs-8px !important,
    $fs-8px !important
  );
  @include setBtnColor(green !important, green !important, $white !important);
  &:hover {
    @include setBtnColor(
      $white !important,
      //background-color
      $white !important,
      //border-color
      green !important//color
    );
    border-color: green !important;
  }
  &:focus {
    box-shadow: none !important;
    @include setBtnColor(
      $white !important,
      $white !important,
      green !important
    );
    border-color: green !important;
  }
  &:disabled {
    @include setBtnColor(green !important, green !important, $white !important);
    cursor: not-allowed;
    border-color: green !important;
  }
}

.custom-blue-btn {
  box-shadow: 0px $fs-8px $fs-6px #72c3db29;
  font-family: $gothamBook;
  font-size: $fs-14px !important;
  border-radius: $fs-4px !important;
  @include setFont(800 !important, normal, normal, normal, center);
  @include setPadding(
    $fs-8px !important,
    $fs-8px !important,
    $fs-8px !important,
    $fs-8px !important
  );
  @include setBtnColor(
    var(--bs-light-blue) !important,
    var(--bs-light-blue) !important,
    $white !important
  );
  &:hover {
    @include setBtnColor(
      $white !important,
      //background-color
      $white !important,
      //border-color
      var(--bs-light-blue) !important//color
    );
    border-color: var(--bs-light-blue) !important;
  }
  &:focus {
    box-shadow: none !important;
    @include setBtnColor(
      $white !important,
      $white !important,
      var(--bs-light-blue) !important
    );
    border-color: var(--bs-light-blue) !important;
  }
  &:disabled {
    @include setBtnColor(
      var(--bs-light-blue) !important,
      var(--bs-light-blue) !important,
      $white !important
    );
    cursor: not-allowed;
    border-color: var(--bs-light-blue) !important;
  }
}

.custom-btn-extra-large {
  font-size: $fs-24px !important;
  font-weight: bold !important;
}

.custom-btn--small {
  font-size: $fs-12px !important;
  @include setPadding(
    $fs-4px !important,
    $fs-4px !important,
    $fs-4px !important,
    $fs-4px !important
  );
}

.custom-btn--very-small {
  @include setPadding(
    $fs-4px !important,
    $fs-8px !important,
    $fs-4px !important,
    $fs-8px !important
  );
  font-size: $fs-16px;
}

.btn-block {
  width: 100%;
}
