/*--------------------------------------------------------------
	Common
--------------------------------------------------------------*/
@import "screen-sizes";
@import "mixins";
@import "fonts";
@import "colors";

:root {

   --bs-stack-grey: #808B80;
   --bs-orinoco-green: #D7D8B3;
   --bs-celeste-green: #D2D1C2;
   --bs-narvik-green: #E6E6D7;
   --bs-melanzane-Violet: #2D262B;
   --bs-white: #FFFFFF;
   --bs-black: #000000;
   --bs-coffee: #2d262b;
   --bs-white-smoke: #e5e5de;
   --bs-light-blue: #3d505c;
   --bs-danger-light: #b75f48;
   --bs-celeste: #D1D0C0;
   --bs-align-left: left;
   --bs-align-right: right;
   --bs-family-MagdalenaAlt: MagdalenaAlt-Medium;
   --bs-family-Saol-Italic: SaolStandard-RegularItalic;
   --bs-Porter-Bold: Porter-Bold;
   --bs-SaolStandard-Thin: SaolStandard-Thin;
   --ease-in: cubic-bezier(0.49, 0.025, 0.65, 0.65);
   --ease-out: cubic-bezier(0.28, 0.44, 0.49, 1);
   --ease-in-out: cubic-bezier(0.49, 0.025, 0.49, 1);
   --bounce: cubic-bezier(0.6, 0, 0.1, 1.4);
   --ease-in-soft: cubic-bezier(0.49, 0.025, 0.685, 1);
   --ease-out-soft: cubic-bezier(0.28, 0, 0.49, 1);
   --ease-in-out-soft: cubic-bezier(0.72, 0, 0.28, 1);
   --ease-in-out-hard: cubic-bezier(0.77, 0, 0.175, 1);
   
  font-size: $fs-16px; // 16px standard size

  // Control the font size across all screens based on
  // root font size
  //xsmall is for screen size less than 320px
  @include xsmall {
    font-size: $fs-13px;
  }
  //phone is for screen sizes 320px to 409px
  @include phone {
    font-size: $fs-15px;
  }
  //iphone is from 410px to 499px
  @include iphone {
    font-size: $fs-20px;
  }
  //phablet is from 500px to 649px
  @include phablet {
    font-size: $fs-20px;
  }
  //tablet is from 650px to 767px
  @include tablet {
    font-size: $fs-22px;
  }
  //desktop is from 768px to 1023px
  @include desktop {
    font-size: $fs-26px;
  }

  //ipad is from 1024px to 1365px
  @include iPad {
    font-size: $fs-16px;
    @include onlyIPadPro{
      font-size: $fs-20px;
    }
  }
 // 1270px to 1440px
  @include desktop-max-portrait{
    font-size: $fs-24px !important;
  }

  //hdRes is from 1366px to 1919px
  @include hdRes {
    font-size: $fs-16px;
  }
  //hdResSmall is from 1920px to 2435px
  @include hdResSmall {
    font-size: $fs-22px;
  }
  //hdResMedium is from 2436px to 3838px
  @include hdResMedium {
    font-size: $fs-38px;
  }
  //hdResLarge is from 3840px
  @include hdResLarge {
    font-size: $fs-50px;
  }
}

html {
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: scrollbar;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: $white !important;
  -webkit-box-shadow: 0 0 0px 1000px $white inset;
  transition: background-color 5000s ease-in-out 0s;
}

//scrollbar

.scoll-bar::-webkit-scrollbar {
  width: $fs-4px;
  height: $fs-4px;
  background-color: $transparent;
}
.scoll-bar::-webkit-scrollbar-thumb {
  background-color: var(--bs-white);
}
.scoll-bar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 $fs-2px var(--bs-stack-grey)!important;
  box-shadow: inset 0 0 $fs-2px var(--bs-stack-grey) !important;
  background-color: $transparent;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
  background-color: $transparent;
}
::-webkit-scrollbar-thumb {
  background-color: var(--bs-stack-grey);
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 $fs-2px var(--bs-stack-grey)!important;
  box-shadow: inset 0 0 $fs-2px var(--bs-stack-grey) !important;
  background-color: $transparent;
}

img{
  width: 100%;
  height: 100%;
}

.admin-container{
  max-width: 90% !important;
}