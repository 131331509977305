$stack-grey: #808B80;
$orinoco-green: #D7D8B3;
$celeste-green: #D2D1C2;
$narvik-green: #E6E6D7;
$melanzane-Violet: #2D262B;
$white: #FFFFFF;
$black: #000000;
$transparent: transparent;
$blue-light: #132966;
$light-red: #b75f48;
$themeColor: #b75f48;