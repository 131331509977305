@import "fonts";
@import "colors";
@import "mixins";
@import "screen-sizes";

.p-very {
  font-size: $fs-8px !important;
  line-height: calc(0.5rem * 1.2) !important;
}

.p-very-small {
  font-size: $fs-9px !important;
  line-height: calc(0.65rem * 1.2) !important;
}

.p-smallest {
  font-size: $fs-10px !important;
  line-height: calc(0.65rem * 1.2) !important;
}

.p-medium-small {
  font-size: $fs-12px !important;
  line-height: calc(0.75rem * 1.2) !important;
}

.p-small {
  font-size: $fs-13px !important;
  line-height: calc(0.8rem * 1.2) !important;
}

.p-medium {
  font-size: $fs-14px !important;
  line-height: calc(0.95rem * 1.2) !important;
}

p {
  font-size: $fs-18px !important;
  line-height: calc(1.125rem * 1.2) !important;
}

.h6-small {
  font-size: $fs-15px !important;
  line-height: calc(0.85rem * 1.14) !important;
}

.h6-medium {
  font-size: $fs-16px !important;
  line-height: calc(1.4rem * 1.14) !important;
}

.h5-small {
  font-size: $fs-18px !important;
  line-height: calc(1.6rem * 1.14) !important;
}

.h4-small {
  font-size: $fs-20px !important;
  line-height: calc(2.125rem * 1.14) !important;
}

.h3-small {
  font-size: $fs-22px !important;
  line-height: calc(1.15rem * 1.14) !important;
}

.h2-small {
  font-size: $fs-24px !important;
  line-height: calc(1.45rem * 1.14) !important;
}

.h2-small-large {
  font-size: $fs-38px !important;
  line-height: calc(2.45rem * 1.14) !important;
}

.h1-very-small {
  font-size: $fs-28px !important;
  line-height: calc(1.25rem * 1.14) !important;
}

.h1-small {
  font-size: $fs-32px !important;
  line-height: calc(1.25rem * 1.14) !important;
}

.h1-small-large {
  font-size: $fs-48px !important;
  line-height: calc(1.25rem * 1.14) !important;
}

.h1-medium-small {
  font-size: $fs-64px;
  line-height: calc(4rem * 1.14) !important;
}

.h1-medium {
  font-size: $fs-96px;
  line-height: calc(6rem * 1.14) !important;
}

.h1-large {
  font-size: 13rem;
  line-height: calc(13rem * 1.14) !important;
}

.h1-extra-medium {
  font-size: 16rem;
  line-height: calc(16rem * 1.14) !important;
}

.h1-extra-small {
  font-size: 20rem;
  line-height: calc(20rem * 1.14) !important;
}

.h1-extra-large {
  font-size: 23rem;
  line-height: calc(23rem * 1.14) !important;
}
