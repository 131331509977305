// font family start

.magdalenaAltMedium {
  font-family: var(--bs-family-MagdalenaAlt) !important;
}

.magdalenaAltRegular {
  font-family: $magdalenaAltRegular !important;
}

.poplarStd {
  font-family: $poplarStd !important;
}

.porterBold {
  font-family: var(--bs-Porter-Bold) !important;
}

.saolStandardLight {
  font-family: $saolStandardLight !important;
}

.saolStandardMedium {
  font-family: $saolStandardMedium !important;
}

.saolStandardRegularItalic {
  font-family: $saolStandardRegularItalic !important;
}

.saolStandardThin {
  font-family: var(--bs-SaolStandard-Thin) !important;
}

.saolStandardRegular {
  font-family: $saolStandardRegular !important;
}

.magdalenaAltSemiBold {
  font-family: $magdalenaAltSemiBold !important;
}

.gothamBook {
  font-family: $gothamBook !important;
}

.gothamBlack {
  font-family: $gothamBlack !important;
}

// font family end

.sub-section-title {
  font-family: var(--bs-family-Saol-Italic);
  color: var(--bs-melanzane-Violet);
  @include setFont(500, normal, normal, normal, center);
  @include setMargin(0, 0, 0, 0);
  @include setPadding(0, 0, 0, 0);
}

.title-white {
  color: var(--bs-white);
}

.title-orinoco-green {
  color: var(--bs-orinoco-green);
}

.title-celeste-green {
  color: var(--bs-celeste-green);
}

.title-stack-grey {
  color: var(--bs-stack-grey);
}

.title-melanzane-Violet {
  color: var(--bs-melanzane-Violet);
}

.title-danger-light {
  color: var(--bs-danger-light);
}

.title-light-blue {
  color: var(--bs-light-blue);
}

.title-blue-light {
  color: $blue-light;
}

.title-white-smoke {
  color: var(--bs-white-smoke);
}

.title-green {
  color: green;
}

.align-left {
  text-align: var(--bs-align-left);
}

.align-right {
  text-align: var(--bs-align-right);
}

.align-center {
  text-align: var(--bs-align-center);
}

.cursor-pointer {
  cursor: pointer !important;
}

.pl-38px {
  padding-left: $fs-38px;
}

.pl-10px {
  padding-left: $fs-10px !important;
}

.lh-16px {
  line-height: $fs-16px !important;
}

.lh-24px {
  line-height: $fs-24px !important;
}

.lh-26px {
  line-height: $fs-26px !important;
}

.lh-48px {
  line-height: $fs-48px !important;
}

.lh-72px {
  line-height: $fs-72px !important;
}

.lh-34px {
  line-height: $fs-34px !important;
}

.wp-1px {
  word-spacing: $fs-1px;
}

.full-height {
  height: 100vh;
}

.txt-underline {
  text-decoration: underline;
}

.common-width {
  width: calc(100% / 2);
}

.txt-uppercase {
  text-transform: uppercase;
}

.bg-celeste-green {
  background-color: var(--bs-celeste-green) !important;
}

.bg-celeste {
  background-color: var(--bs-celeste) !important;
}

.bg-narvik-green {
  background-color: var(--bs-narvik-green) !important;
}

.bg-stack-grey {
  background-color: var(--bs-stack-grey) !important;
}

.bg-grey {
  background-color: #c2c1b3 !important;
}

.bg-bs-white-smoke {
  background-color: #ddd !important;
}

.bg-light-blue {
  background-color: var(--bs-light-blue);
}

.full-width {
  width: 100%;
}

.fw-600 {
  font-weight: 600;
}

.fw-800 {
  font-weight: 800;
}

.align-center {
  text-align: center;
}

.pt-4rem {
  padding-top: 4rem;
}

/* Float Shadow */
$defaultDuration: 0.3s;
$primaryColour: #ececec;
$secondaryColour: #666;
$shadowColour: rgba(0, 0, 0, 0.6);

.float-shadow {
  display: inline-block;
  position: relative;
  transition-duration: $defaultDuration;
  transition-property: transform;

  @include hideTapHighlightColor();
  @include hardwareAccel();
  @include improveAntiAlias();

  &:before {
    pointer-events: none;
    position: absolute;
    z-index: -1;
    content: "";
    top: 100%;
    left: 5%;
    height: 10px;
    width: 90%;
    opacity: 0;
    background: radial-gradient(
      ellipse at center,
      rgba(0, 0, 0, 0.35) 0%,
      rgba(0, 0, 0, 0) 80%
    ); /* W3C */
    transition-duration: $defaultDuration;
    transition-property: transform opacity;
  }

  &:hover {
    transform: translateY(-5px); /* move the element up by 5px */

    &:before {
      opacity: 1;
      transform: translateY(
        5px
      ); /* move the element down by 5px (it will stay in place because it's attached to the element that also moves up 5px) */
    }
  }
}

.width-25 {
  width: 25%;
}

.bg-coffe {
  background-color: var(--bs-coffee);
}

.bt-0 {
  bottom: 0 !important;
}
.bt-32px {
  bottom: $fs-32px !important;
}

.width-95 {
  width: 95%;
}

.width-5 {
  width: 5%;
}

.fs-italic {
  font-style: italic;
}

.not-found {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.type-contianer {
  border: $fs-1px solid var(--bs-narvik-green);
  border-radius: $fs-4px;
  padding: $fs-64px 0 !important;
  cursor: pointer;
}

.dp-none {
  display: none;
}

.seva-content-container {
  min-height: 100vh;
  background-color: var(--bs-narvik-green);
  .seva-img-list-contianer {
    height: 40vh;
    @include onlyIPadPro{
      height: 45vh;
    }
    @include mobileScreen{
      height: 50vh;
      @include xsmall{
        height: 60vh;
      }
     @include desktop{
       height: 45vh;
     }
    }
    //   @include iPad{
    //     height: 44vh;
    //   }
    //   @include iPad-portrait{
    //     height: 53vh;
    //   }
    //   @include desktopLarge{
    //     height: 60vh;
    //   }
    //   @include desktop-max-portrait{
    //     height: 53vh;
    //   }
    //   @include hdResLarge{
    //     height: 54vh;
    //   }
  }
  .seva-img--position {
    // position: absolute;
    // top: 0.35rem;
    width: 80%;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: $fs-4px;
    overflow: hidden;
    height: 100%;
    // height: -webkit-fill-available;
    img {
      object-fit: contain;
      height: 60%;
    }
  }

  .seva-btn--container {
    position: relative;
    margin-top: -3rem;
    @include onlyIPadPro{
      margin-top: -4rem;
    }
    @include desktop-max-portrait {
      margin-top: -4rem;
    }
  }

  .seva-btn--position {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0rem;
    width: max-content;
    margin: auto;
    cursor: pointer;
    padding: 1rem;
    height: 28%;
    display: flex;
    align-items: center;
  }

  .seva-title--position {
    position: absolute;
    top: 1rem;
    left: 0;
    right: 0;
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.seva-info-content-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  background-color: var(--bs-narvik-green);
  .seva-info-img--position {
    width: 80%;
    height: 100%;
    margin: auto;
    @include onlyIPadPro{
      width: 90%;
    }
    img {
      // border-top-left-radius: 20px;
      // border-top-right-radius: 20px;
      // border: 1px solid $stack-grey;
      // border-bottom: 0;
      object-fit: contain;
    }
  }

  .seva-info-img-title--position {
    position: absolute;
    bottom: -3.35rem;
    left: 0;
    right: 0;
    width: 100%;
    @include onlyIPadPro{
      bottom: -2.75rem;
    }
  }

  .seva-info-title--position {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .seva-information--container {
    border: 2px solid $stack-grey;
    margin-top: 4rem;
    padding: $fs-24px $fs-16px;
  }
  .seva-quote-container {
    position: absolute;
    top: -37px;
    padding: 0.5rem 0.85rem;
    background-color: var(--bs-narvik-green);
    @include mobileScreen{
      top: -18px;
      @include desktop{
        top: -35px;
      }
    }
  }

  .save-info--btn-container {
    position: absolute;
    width: 18%;
    left: 0;
    right: 3rem;
    margin-left: auto;
    cursor: pointer;
    bottom: -1.25rem;
    @include onlyIPadPro{
      width: 25%;
    }
    @include mobileScreen{
      width: 30%;
    }
  }

  .save-info--btn-position {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.seva-donate-content-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: var(--bs-narvik-green);
  .save-donate-title--container {
    position: absolute;
    top: $fs-16px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    left: 0;
    right: 0;
    margin: auto;
    cursor: pointer;
    padding-top: $fs-16px;
  }

  .seva-donate-title--position {
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    margin: auto;
    cursor: pointer;
  }
}

.donate-btn {
  width: 50%;
  @include mobileScreen{
    width: 60%;
    @include desktop{
      width: 50%;
    }
  }
  &.donate-btn--small {
    width: calc(100% / 3 - 50px);
  }
  .donate-btn-text--position {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100%;
  }
}

.sub-seva-donate-btn {
  .donate-btn-text--position {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100%;
  }
}

.hover-one {
  &:hover {
    transition: all 0.5s;
    transform: scale(1.2);
  }
}

.seva-form-container {
  background-color: $white;
  border-radius: $fs-12px;
  //padding: $fs-16px;
}

.width_38 {
  width: 38%;
}

.width_70{
  width: 70%;
}

.width_45{
  width: 45%;
}

.ml-auto {
  margin-left: auto;
}

.pt-15 {
  padding-top: 0.15rem;
}

.pl-6px {
  padding-left: $fs-6px !important;
}

.width_24px {
  width: $fs-24px;
}

.payment-form-info--container {
  background: #b75f48;
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  padding-left: $fs-16px;
  padding-right: $fs-16px;
  padding-top: 1.95rem !important;
  @include mobileScreen{
    padding-left: $fs-4px;
    padding-right: $fs-4px;
    padding-top: $fs-16px;
    padding-bottom: $fs-16px;
  }
  .payment-btn--position {
    position: absolute;
    top: -2rem;
    right: 2.5rem;
    @include onlyIPadPro{
      top: -1rem;
      right: 1.5rem;
    }
    @include mobileScreen{
      top: -1rem;
      right: 0.5rem;
    }
  }
}

$spacing: 1rem;

* {
  box-sizing: border-box;
}

$step: 1;
$loops: round(100 / $step);
$increment: 360 / $loops;
$half: round($loops / 2);

.progress--circle {
  position: relative;
  display: inline-block;
  // margin: $spacing;
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 50%;
  background-color: $white;
  @include mobileScreen{
    width: 2.5rem;
    height: 2.5rem;
  }
  &:before {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: white;
  }
  &:after {
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $themeColor;
  }
}

.progress--pie {
  &:before {
    display: none; /* Get rid of white circle for "pie chart style" meter */
  }
}

.pie-position {
  position: absolute;
  top: -1.95rem;
  left: 0.15rem;
  right: 0;
  display: flex;
  align-items: center;
  @include mobileScreen{
    top: -1.25rem;
    left: 0.45rem;
  }
  img {
    width: 5rem;
    @include mobileScreen{
      width: 3.25rem;
    }
  }
}

@for $i from 0 through $loops {
  .progress--bar.progress--#{$i * $step}:after {
    width: $i * $step * 1%;
  }
  .progress--circle.progress--#{$i * $step}:after {
    @if $i < $half {
      $nextDeg: 90deg + ($increment * $i);
      background-image: linear-gradient(
          90deg,
          $white 50%,
          transparent 50%,
          transparent
        ),
        linear-gradient($nextDeg, $themeColor 50%, $white 50%, $white);
    } @else {
      $nextDeg: -90deg + ($increment * ($i - $half));
      background-image: linear-gradient(
          $nextDeg,
          $themeColor 50%,
          transparent 50%,
          transparent
        ),
        linear-gradient(270deg, $themeColor 50%, $white 50%, $white);
    }
  }
}

.line-divider {
  border-top: 1px solid $white;
  width: 90%;
  margin: auto;
}

.login-bg-color {
  background-color: var(--bs-narvik-green);
}

.box-shadow {
  box-shadow: 0px $fs-3px $fs-3px $fs-3px #d7d8b3;
  border-radius: $fs-16px;
}

.thank-you-img--container {
  width: 45%;
  margin: auto;
  @include mobileScreen{
    width: 65%;
  }
}

.ml-12px {
  margin-left: $fs-12px;
}
.fs-10px {
  font-size: $fs-10px !important;
}

.dropzone {
  &:focus {
    border: none !important;
    outline: 0 !important;
  }
}

.file-thumbnail-container {
  position: absolute;
  bottom: 0.5rem;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
}

.br-lr-8px {
  border-top-left-radius: $fs-8px;
  border-top-right-radius: $fs-8px;
}

.blog-img--title-container {
  position: absolute;
  top: 60%;
  width: 75%;
  margin: auto;
  left: 0;
  right: 0;
  padding: 10px;
  h3 {
    span {
      background-color: var(--bs-white-smoke);
      color: $black;
      padding: 0.15rem 0.5rem;
      // box-shadow: -10px 0px 0 7px var(--bs-white-smoke),
      //   10px 0px 0 7px var(--bs-white-smoke), 0 0 0 7px var(--bs-white-smoke);
      box-decoration-break: clone;
    }
  }
  // background-color: var(--bs-white-smoke);
}

.blog-params-container {
  width: 85%;
  @include mobileScreen{
    width: 100%;
  }
}

.blog-book-container {
  width: 75%;
  background-color: var(--bs-celeste-green);
  img {
    padding: 1rem;
  }
  @include mobileScreen{
    width: 100%;
  }
}

.blog-divider-line {
  width: 20%;
  border-bottom: $fs-1px solid $black;
  margin: auto;
}

.blog-sharing-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40%;
  margin: auto;
  .common-width {
    width: 50%;
    cursor: pointer !important;
  }
}

.thank-you-bg-img--container {
  position: relative;
  width: 80%;
  margin: auto;
  @include mobileScreen{
    width: 100%;
  }
  .thank-message-info {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.donate-go-back-container {
  position: fixed;
  bottom: 1rem;
  left: 2rem;
  right: 0;
  width: max-content;
  z-index: 999;
  cursor: pointer;
}

.blog-list-more-item {
  position: relative;
  padding: 0.5rem;
}

.blog-footer {
  width: 40%;
  @include mobileScreen{
    width: 60%;
    margin: auto;
    @include desktop{
      width: 40%;
      margin: auto;
    }
  }
  .social-icon {
    width: 65%;
  }
}

.br-1px {
  border: $fs-1px solid $black;
}

.mt-7rem {
  margin-top: 7rem !important;
}

.mt-4rem {
  margin-top: 4rem !important;
}

.arrow-img {
  width: 60%;
  object-fit: contain;
  @include mobileScreen{
    width: 40%;
  }
}

.pl-72px {
  padding-left: $fs-72px !important;
}

.scroll-arrow--container {
  width: 3%;
  padding: 0.5rem;
  margin: auto;
  transform: rotate(180deg);
  cursor: pointer;
  @include onlyIPadPro{
    width: 8%;
  }
  @include mobileScreen{
    width: 12%;
    @include tablet{
      width: 8%;
    }
    @include desktop{
      width: 10%;
    }
  }
}

.footer-title {
  border: 1px solid var(--bs-stack-grey);
  padding: $fs-2px;
  padding-top: $fs-4px;
}

.pt-14rem {
  padding-top: 14rem;
}

.overflow-hidden {
  overflow: hidden;
}

.link-hover {
  text-decoration: none !important;
  &:hover {
    color: var(--bs-stack-grey) !important;
  }
}

.pr-64px {
  padding-right: $fs-64px;
}

.width_75 {
  width: 75%;
  margin: auto;
}

.submit-form-container{
  border: $fs-1px solid var(--bs-stack-grey);
  padding: 0 !important;
  .submit-form--img-container {
    width: 75%;
    margin: auto;
  }
}

.mb-auto{
  @include mobileScreen{
    margin: auto;
  }
}

.pt-5rem{
  padding-top: 5.5rem;
}

.fd-cr{
  flex-direction: column-reverse;
}

.width_50{
  width: 50%;
}

.br-contianer{
  border: 1px solid var(--bs-stack-grey);
  border-radius: $fs-2px;
  padding: $fs-4px;
}

.width_60{
  width: 60%;
}

.width_40{
  width: 40%;
}

.pd_8px{
  padding: $fs-8px
}

.br-bt-4px{
  border-bottom: 4px solid $white;
  width: 95% !important;
  margin: auto;
}

.pl-0{
  padding-left: 0;
}

.pr-0{
  padding-right: 0;
}

.width_10{
  width: 10%;
}
.pt-32px{
  @include onlyIPadPro{
    padding-top: $fs-32px;
  }
}

.item_width_70{
  width: 70%;
  @include desktop{
    width: 48%;
  }
}
.width_30 {
  width: 90%;
  padding: 1rem 0.5rem;
}
.blog-mobile-menu-container{
  position: fixed;
  width: 100%;
  background-color: var(--bs-light-blue);
  overflow-y: hidden;
  z-index: 999;
  top: 4rem;
  .width_9rem{
    min-width: 9rem;
  }
  .width_6rem{
    min-width: 6rem;
  }
}

.mt-3rem{
  margin-top: 3rem;
}

.height_85{
  height: 85vh;
}

.mobile-pb12px{
  @include mobileScreen{
    padding-bottom: $fs-12px;
  }
}

.icon_width_30{
  width: 30%;
}