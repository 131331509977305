.common-form-control {
  font-family: $gothamBook !important;
  font-size: $fs-13px !important;
  color: $black !important;
  border-radius: $fs-2px !important;
  border-color: var(--bs-white-smoke) !important;
  //background-color: $dim-grey !important;
  height: $fs-32px !important;
  @include setFont(
    600 !important,
    normal !important,
    normal !important,
    normal !important,
    left !important
  );
  &:focus {
    color: $black !important;
    box-shadow: none !important;
    border-color: var(--bs-white-smoke) !important;
  }
  &::placeholder {
    opacity: 0.45 !important;
    font-size: $fs-10px !important;
  }
  @include mobileScreen {
    height: $fs-38px !important;
    font-size: $fs-24px;
  }
  // @include mobileScreen {
  //   height: $fs-38px !important;
  // }
}

.common-form-control--large{
  font-family: $gothamBook !important;
  font-size: $fs-18px !important;
  color: $black !important;
  border-radius: $fs-2px !important;
  border: $fs-2px solid var(--bs-light-blue) !important;;
  background-color: transparent !important;
  height: $fs-64px !important;
  @include setFont(
    600 !important,
    normal !important,
    normal !important,
    normal !important,
    left !important
  );
  &:focus {
    color: $black !important;
    box-shadow: none !important;
    background-color: transparent !important;
    border: $fs-2px solid var(--bs-light-blue) !important;;
  }
  &::placeholder {
    opacity: 0.45 !important;
    font-size: $fs-10px !important;
  }
}

.common-label {
  font-size: $fs-13px !important;
  font-family: $gothamBook !important;
  color: $black !important;
  margin-bottom: 0 !important;
  @include mobileScreen {
    font-size: $fs-16px;
  }
}

.form-common-label{
  font-size: $fs-20px !important;
  font-family: $gothamBook !important;
  color: var(--bs-melanzane-Violet) !important;
  margin-bottom: 0 !important;
}

.common-labe-small {
  font-family: $gothamBook !important;
  font-size: $fs-12px;
  color: $black !important;
}

.text-position {
  text-align: right;
  @include mobileScreen {
    text-align: left;
  }
}

textarea.form-control {
  @include mobileScreen {
    height: 6rem !important;
  }
  height: 4rem !important;
}

textarea.textarea-form-control {
  height: 10rem !important;
}

.has-error {
  .form-control {
    border-color: var(--bs-danger) !important;
  }
}

.help-block {
  color: var(--bs-danger);
  margin-bottom: 0 !important;
  padding-top: $fs-4px;
  font-family: $gothamBook !important;
  font-size: $fs-10px !important;
}

.form-group {
  margin-bottom: $fs-8px !important;
}

.form-group-large {
  margin-bottom: $fs-16px !important;
}


.input-group-btn-small {
  .dropdown-toggle {
    background: transparent;
    font-family: $gothamBook !important;
    font-size: $fs-13px !important;
    color: $black !important;
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-color: var(--bs-white-smoke) !important;
    @include setFont(
      600 !important,
      normal !important,
      normal !important,
      normal !important,
      left !important
    );
    height: $fs-32px !important;
    // @include mobileScreen {
    //   height: $fs-54px !important;
    //   font-size: $fs-24px !important;
    // }
    @include mobileScreen {
      height: $fs-38px !important;
    }
    &:hover {
      background-color: transparent !important;
    }
    &:focus {
      box-shadow: none !important;
    }
  }
  .dropdown-menu {
    min-width: 5rem !important;
    .dropdown-item {
      background: transparent;
      font-family: $gothamBook !important;
      font-size: $fs-13px !important;
      color: $black !important;
      @include setFont(
        600 !important,
        normal !important,
        normal !important,
        normal !important,
        left !important
      );
      &:hover {
        background: var(--bs-white-smoke) !important;
      }
    }
  }
}

.btn-check:active + .btn-secondary,
.btn-check:checked + .btn-secondary,
.btn-secondary.active,
.btn-secondary:active,
.show > .btn-secondary.dropdown-toggle {
  background-color: transparent !important;
}
